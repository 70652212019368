<template>
    <div class="teacherFormBox">
        <el-dialog class="cus_dialog" :title="modalTitle" width="1000px" :visible.sync="visible" :close-on-click-modal="false">
            <el-form :model="formData" inline :rules="rules" ref="adminUserInfo" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="所在部门:" prop="dept_id">
                            <!-- <el-input class="elInput" v-model="formData.dept_id" placeholder="请选择部门"></el-input> -->
<!--                            <el-select v-model="formData.dept_id" placeholder="请选择部门">
                                <el-option v-for="(item,index) in depts" :key="index"
                                           :label="item.dept_name" :value="item.id"></el-option>
                            </el-select>-->
                          <el-cascader ref="treeCascader" :options="treeData" :props="firstCascaderProps"
                                       v-model="formData.dept_id" :show-all-levels="false"
                                       @change="(e) => handleCascaderChange(e)" placeholder="请选择" clearable>
                          </el-cascader>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="职工号:" prop="ucode">
                            <el-input class="elInput" v-model="formData.ucode" placeholder="请输入职工号"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="姓名:" prop="username">
                            <el-input class="elInput" v-model="formData.username" placeholder="请输入姓名"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="手机号:" prop="phone">
                            <el-input class="elInput" v-model="formData.phone" placeholder="请输入手机号"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="性别:" prop="gender_id">
                            <el-radio-group size="small" v-model="formData.gender_id">
                                <el-radio :label="1">男</el-radio>
                                <el-radio :label="2">女</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="民族:" prop="ethnic_id">
                            <el-select v-model="formData.ethnic_id" placeholder="请选择">
                                <el-option v-for="(item,index) in dictData.ethnic" :key="index"
                                           :label="item.name" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="籍贯:" prop="hometown">
                            <el-input class="elInput" v-model="formData.hometown" placeholder="请输入籍贯"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="政治面貌:" prop="political_status_id">
                            <el-select v-model="formData.political_status_id" placeholder="请选择">
                                <el-option v-for="(item,index) in dictData.politicalStatus" :key="index"
                                           :label="item.name" :value="item.id"></el-option>
                            </el-select>
                        
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="证件类型:" prop="certificate_type_id">
                            <el-select v-model="formData.certificate_type_id" placeholder="请选择证件类型">
                                <el-option v-for="(item,index) in dictData.idType" :key="index"
                                           :label="item.name" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="证件号码:" prop="certificate_number">
                            <el-input class="elInput" v-model="formData.certificate_number" @change="getBirthdayFromIdCard"
                                      placeholder="请输入证件号码"></el-input>
                        </el-form-item>
                    </el-col>


                    <el-col :span="12">
                        <el-form-item label="出生日期:" prop="born_date">

                            <el-date-picker
                                v-model="formData.born_date"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期" class="elInput">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                      
                        <el-form-item label="是否在编:" prop="preparation_status">
                            <el-select v-model="formData.preparation_status" placeholder="请选择是否编制">
                                <el-option value="" label="未知">未知</el-option>
                                <el-option :value="1" label="在编"></el-option>
                                <el-option :value="0" label="非在编"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                   

                    <el-col :span="12">
                        <el-form-item label="任职名称:" prop="job_title_id">
                            <el-select v-model="formData.job_title_id" placeholder="请选择">
                                <el-option v-for="(item,index) in dictData.jobTitle" :key="index"
                                           :label="item.name" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="职称级别:" prop="title_level_id">
                            <el-select v-model="formData.title_level_id" placeholder="请选择">
                                <el-option v-for="(item,index) in dictData.jobTitleLevel" :key="index"
                                           :label="item.name" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="最高学历:" prop="education_id">
                            <el-select v-model="formData.education_id" placeholder="请选择">
                                <el-option v-for="(item,index) in dictData.education" :key="index"
                                           :label="item.name" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="最高学位:" prop="academic_degree_id">
                            <el-select v-model="formData.academic_degree_id" placeholder="请选择">
                                <el-option v-for="(item,index) in dictData.academicDegree" :key="index"
                                           :label="item.name" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>


                    <el-col :span="24">
                        <el-form-item label="排序号" prop="sort">
                            <el-input-number :min="1" v-model.trim="formData.sort" placeholder="请输入排序号(数字)"
                                             controls-position="right"/>
                        </el-form-item>
                    </el-col>
					
					<el-col :span="12">
					    <el-form-item label="是否锁定:" prop="locked">
					        <el-select v-model="formData.locked" placeholder="请选择">
					            <el-option label="正常" :value="0">正常</el-option>
					            <el-option label="锁定" :value="1">锁定</el-option>
					        </el-select>
					    </el-form-item>
					</el-col>
					
					<el-col :span="12">
					    <el-form-item label="在职状态:" prop="locked">
					        <el-select v-model="formData.job_state" placeholder="请选择">
					            <el-option label="在职" :value="1">在职</el-option>
					            <el-option label="离职" :value="2">离职</el-option>
								<el-option label="调岗" :value="3">调岗</el-option>
								<el-option label="其他" :value="4">其他</el-option>
					        </el-select>
					    </el-form-item>
					</el-col>

                    <el-col :span="24">
                        <el-form-item label="备注:">
                            <el-input class="elInput" type="textarea" :autosize="{ minRows: 3}"
                                      v-model="formData.remark"
                                      placeholder="备注信息不超过200字"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-row slot="footer">
                <el-button size="small" type="default" @click="handleCancel">取消</el-button>
                <el-button size="small" type="primary" @click="handleOk('adminUserInfo')">保存</el-button>
            </el-row>
        </el-dialog>

    </div>
</template>
<script>
import api from '@/api/api';
import dict from '@/api/dictionary'

export default {
    name: 'Form',
    data() {
        var checkPhone = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('手机号不能为空'));
            } else {
                const reg = /^1[3|4|5|7|8][0-9]\d{8}$/

                if (reg.test(value)) {
                    callback();
                } else {
                    return callback(new Error('请输入正确的手机号'));
                }
            }
        };

        return {
            visible: false,
            formData: {
                sort:500
            },
            rules: {
                dept_id: [{required: true, message: '请选择部门', trigger: 'blur'} ],
                ucode: [{required: true, message: '请输入工号', trigger: 'blur'} ],
                username: [{required: true, message: '请输入用户名', trigger: 'blur'} ],
                gender_id: [{required: true, message: '请选择性别', trigger: 'blur'} ],
                phone: [{required: true, validator: checkPhone, trigger: 'blur'} ],
              
            },

            treeData: [],
            firstCascaderProps: {
                label: 'dept_name',
                value: 'id',
                children: 'children',
                emitPath: false,
                //multiple: true,
                checkStrictly: true
            },
            dictData:{},//字典
            depts:null, //部门
        }
    },
    props: {
        modalTitle: {
            type: String,
            default: '添加'
        },
        editOptions: {
            type: Object
        },

    },
    methods: {
        getDictVal() {
            let _this = this
            this.$http.post('/api/sys_dictionary_list').then(res => {
                console.log(res.data);
                if (res.data && res.data.length > 0) {
                    let obj = {}
                    res.data.map(a => {
                        let arr = []
                        if (a.key_values) {
                            arr = a.key_values ? JSON.parse(a.key_values) : []
                            arr.map(a => {
                                a.id = Number(a.id)
                            })
                        }
                        obj[a.key_type] = [...arr]
                    })
                
                    _this.dictData = obj
                }
            })
            this.$http.post('/api/sys_dept_manage_tree').then(res=>{
                console.log(res.data[0].children);
                this.depts=res.data[0].children
            })
        },

        //tree
        getOrgList() {
            let _this = this
            this.$http.post(api.tea_deptList).then(res => {
                if (res.data && res.data.length > 0) {
                    _this.deleteEmptyGroup(res.data).then(result => {
                        _this.treeData = result
                    })
                } else {
                    this.$message.error(res.msg)
                }

            })
        },
        // 处理没有children的分组
        deleteEmptyGroup(treeData) {
            return new Promise(resolve => {
                function traversal(data) {
                    data.map(item => {
                        for (let info in item) {
                            if (item['children']) {
                                if (item['children'].length > 0) {
                                    traversal(item['children'])
                                }
                                if (item['children'].length == 0) {
                                    delete item['children']
                                }
                            }
                        }
                    })
                }

                traversal(treeData)
                resolve(treeData)
            })
        },
        handleOk(formName) {
            let _this = this
            _this.$refs[formName].validate((valid) => {
                if (valid) {
                    _this.$emit('saveForm', this.formData)
                }
            });
            // window.localStorage.setItem('depts',this.formData.depts)
        },
        handleCancel() {
            this.visible = false
        },
        show() {
            this.getDictVal()
            this.getOrgList()
            this.formData = Object.assign({}, this.editOptions)
            this.visible = true
        },
        handleCascaderChange(e) {
            console.log(1, e)
        },

        getBirthdayFromIdCard: function(idCard) {
            let _this = this
            let birthday = "";
            if (idCard != null && idCard != "") {
                if (idCard.length == 15) {
                    birthday = "19" + idCard.substr(6, 6);
                    birthday = `${birthday.substr(0, 4)}-${birthday.substr(4, 2)}-${birthday.substr(6, 2)}`
                    this.formData.born_date = birthday
                } else if (idCard.length == 18) {
                    birthday = idCard.substr(6, 8);
                    birthday = `${birthday.substr(0, 4)}-${birthday.substr(4, 2)}-${birthday.substr(6, 2)}`
                    this.formData.born_date = birthday
                }
                this.formData = {
                    ..._this.formData
                }
            }

        },

    }
}
</script>
<style lang="less" type="text/less">
.teacherFormBox{
.cus_dialog {
    .el-form {
        .el-form-item{
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            .el-form-item__content{
                flex: 1;
                .elInput{
                    width: 80%;
                }
                .el-select{
                    width: 80%;
                }
            }
        }
    }
}
}
</style>