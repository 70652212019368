<template>
	<div class="wraper flexCloumn teacherBox">
		<div class="headbar flexStart" style="height: 60px; padding-left: 0">
			<el-select v-model="searchForm.ktype" size="small" placeholder="请选择"
				style="width: 150px; margin-right: 10px" @change="
          () => {
            searchForm.keyword = '';
            $forceUpdate();
          }
        ">
				<el-option label="姓名" value="username">姓名</el-option>
				<el-option label="工号" value="ucode">工号</el-option>
				<el-option label="手机号" value="phone">手机号</el-option>
				<el-option label="所属部门" value="dept_id">所属部门</el-option>
			</el-select>
			<el-input v-if="searchForm.ktype != 'dept_id'" v-model="searchForm.keyword" size="small" placeholder="请输入"
				clearable style="max-width: 200px; margin-right: 10px" @input="
          () => {
            $forceUpdate();
          }
        " />
			<el-cascader v-if="searchForm.ktype == 'dept_id'" :options="treeData" :props="deptCascaderProps"
				size="small" v-model="searchForm.keyword" :show-all-levels="false" placeholder="请选择" clearable
				style="max-width: 200px; margin-right: 10px"></el-cascader>

			<el-button size="small" icon="el-icon-search" @click="onSearch" plain type="primary">查询</el-button>
			<el-button size="small" icon="el-icon-refresh" @click="onReset" type="warning">重置</el-button>

			<el-button size="small" icon="el-icon-download" type="primary" plain style="margin-left: 20px">
				<a href="./static/ImportTemps/teachers.xls" target="_blank"
					style="text-decoration: none; color: inherit; margin-right: 20px">下载教师模板</a>
			</el-button>
			<el-button size="small" icon="el-icon-upload" type="primary" plain @click="updDialog = true">导入教师
			</el-button>
		</div>

		<div class="flexStart" style="height: 40px">
			<el-button size="small" icon="el-icon-plus" @click="formAdd" type="primary">添加教师 </el-button>

			<el-button size="small" icon="el-icon-guide" @click="setDept" type="primary" plain>关联部门 </el-button>

			<el-button size="small" icon="el-icon-guide" @click="setAuth" type="primary" plain>分配角色 </el-button>
		</div>
		<div style="width: 100%; height: calc(100% - 120px); margin-top: 10px">
			<Table style="width: 100%;height:100%;" :tableData="tableData" :page="page" @handleModify="handleModify"
				@handleDel="handleDel" @handleSelectionChange="handleSelectionChange"
				@handlePageChange="handlePageChange" @showOneDept="showOneDept" @showOneTeacher="showOneTeacher"
				@resetPwd="resetPwd" @releaseDept="releaseDept"> </Table>
		</div>
		<el-dialog title="选择部门" :visible.sync="isShowDept" :close-on-click-modal="false" :destroy-on-close="true"
			width="800px">
			<el-form inline :rules="deptRules" ref="deptForm" label-width="90px">
				<el-row v-for="(item, index) in formData.depts" :key="index" class="flexStart"
					style="display: flex !important">
					<el-form-item :label="'所属部门' + (index + 1)" prop="dept_id">
						<el-cascader ref="treeCascader" :options="treeData" :props="deptCascaderProps"
							v-model="item.dept_id" :show-all-levels="false"
							@change="(e) => handleCascaderChange(e, index)" placeholder="请选择" clearable> </el-cascader>
					</el-form-item>
					<el-form-item label="部门角色" prop="item_desc">
						<el-select v-model="item.role_id" placeholder="请选择角色" multiple @change="$forceUpdate()">
							<el-option v-for="(item1, index1) in item.deptRoleList" :key="index1"
								:label="item1.role_name" :value="item1.role_id">{{ item1.role_name }} </el-option>
						</el-select>
					</el-form-item>
					<el-button size="small" @click="delDept(index)" plain type="danger"
						style="margin-left: 10px; margin-bottom: 24px">删除 </el-button>
				</el-row>
				<el-row class="flexStart">
					<el-form-item label="">
						<el-button size="small" icon="el-icon-plus" @click="addDept" type="primary" plain>添加所属部门
						</el-button>
					</el-form-item>
				</el-row>
			</el-form>

			<div slot="footer">
				<el-button type="primary" icon="el-icon-s-claim" size="small" @click="saveDept">保存</el-button>
			</div>
		</el-dialog>

		<el-dialog title="选择角色" :visible.sync="isShowRole" :close-on-click-modal="false" :destroy-on-close="true"
			width="400px" top="60px">
			<el-tree ref="authTree" :props="{ children: 'children', label: 'role_name' }" node-key="id" :data="authData"
				:default-expand-all="true" show-checkbox> </el-tree>

			<div style="text-align: right; padding: 10px; margin-top: 10px; border-top: 1px solid #ccc">
				<el-button type="primary" icon="el-icon-s-claim" size="small" @click="saveRole">保存 </el-button>
			</div>
		</el-dialog>

		<el-dialog title="导入教师" :visible.sync="updDialog" :close-on-click-modal="false" :destroy-on-close="true"
			width="400px" top="60px">
			<div style="text-align: center; color: orangered; padding-bottom: 20px">请确保教师手机号格式正确！</div>
			<div style="text-align: center; color: orangered; padding-bottom: 50px">
				登录密码为手机号后六位，<br />
				若没填写手机号，则密码默认为123456
			</div>
			<div style="text-align: right">
				<el-upload style="display: inline-block; margin-right: 20px" action="" :http-request="uploadTemp"
					:on-success="uploadFiles" :show-file-list="false" name="image">
					<el-button size="small" icon="el-icon-upload2" type="primary">选择文件 </el-button>
				</el-upload>
			</div>
		</el-dialog>

		<Form ref="visiable_form" :modalTitle="modalTitle" :editOptions="editOptions" @saveForm="saveForm"></Form>
	</div>
</template>
<script>
	import api from "@/api/api";

	import Form from "./components/TeacherForm";
	import Table from "./components/TeacherTable";

	export default {
		name: "user",
		components: {
			Form,
			Table,
		},
		data() {
			var checkPhone = (rule, value, callback) => {
				if (!value) {
					return callback(new Error("手机号不能为空"));
				} else {
					const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;

					if (reg.test(value)) {
						callback();
					} else {
						return callback(new Error("请输入正确的手机号"));
					}
				}
			};
			return {
				searchForm: {
					ktype: "username",
				}, //查询
				//dialog
				visible: false,
				isShowModal: false,
				isShowDept: false,
				isShowRole: false,
				updDialog: false,
				modalTitle: "添加",
				userinfo: {
					username: "",
				},
				formData: {
					depts: [{
						dept_id: "",
						role_id: 0,
						deptRoleList: [],
					}, ],
				},

				rules: {
					username: [{
						required: true,
						message: "请输入姓名",
						trigger: "blur",
					}, ],
					ucode: [{
						required: true,
						message: "请输入工号",
						trigger: "blur",
					}, ],
					phone: [{
						validator: checkPhone,
						trigger: "blur",
					}, ],
				},
				//tree
				treeData: [],
				firstCascaderProps: {
					label: "dept_name",
					value: "id",
					children: "children",
					emitPath: false,
					multiple: true,
					checkStrictly: true,
				},
				deptCascaderProps: {
					label: "dept_name",
					value: "id",
					children: "children",
					emitPath: false,
					checkStrictly: true,
				},
				//table
				tabMapOptions: [{
					label: "教师列表",
					key: "CN",
				}, ],
				activeName: "CN",
				tableData: [],
				baseList: [],
				page: {
					showPage: true,
					pageIndex: 1,
					pageSize: 50,
					total: 0,
					pageSizes: [10, 50, 100],
				},

				multipleSelection: [],
				deptId: null,
				deptInfo: {},
				deptRoleIds: "",
				deptRules: {
					username: [{
						required: true,
						message: "请输入姓名",
						trigger: "blur",
					}, ],
					ucode: [{
						required: true,
						message: "请输入工号",
						trigger: "blur",
					}, ],
					phone: [{
						validator: checkPhone,
						trigger: "blur",
					}, ],
				},
				deptRoleList: [],
				tchId: null,
				authData: [],
				editOptions: {},
			};
		},
		watch: {
			activeName(val) {
				this.$router.push(`${this.$route.path}?tab=${val}`);
			},
		},
		created() {
			const tab = this.$route.query.tab;
			if (tab) {
				this.activeName = tab;
			}
		},
		mounted() {
			this.getUserinfo();
		},
		methods: {
			getUserinfo() {
				this.$http.post("/api/get_teacher_info").then((res) => {
					this.userinfo = res.data;
					this.getList();
					this.getOrgList();
					this.getRoleList();
				});
			},
			uploadFiles(e) {
				this.$http
					.post("/api/importTeachersXls", {
						url: e.src,
					})
					.then((res) => {
						if (res.data.msg && typeof res.data.msg == "object" && res.data.msg.length > 0) {
							let html = "";
							for (let msg of res.data.msg) {
								html += "<div style='font-size:12px'>" + msg + "</div>";
							}
							this.$alert(html, "未导入数据", {
								dangerouslyUseHTMLString: true,
							});
						} else {
							this.$message.success("导入成功");
						}
						this.getList();
						this.updDialog = false;
					});
			},
			//tree
			getOrgList() {
				let _this = this;
				return new Promise((resolve) => {
					this.$http.post("/api/sys_department_tree").then((res) => {
						if (res.data && res.data.length > 0) {
							_this.deleteEmptyGroup(res.data).then((result) => {
								_this.treeData = result;
								if (!_this.deptId) {
									// _this.getList();
								}
							});
						}
					});
				});
			},
			getRoleList() {
				this.$http
					.post("/api/zichan_sys_role_list", {
						pagesize: 100,
					})
					.then((res) => {
						this.authData = res.data;
					});
			},
			getDeptRoleList(id, index) {
				let _this = this;
				return new Promise((resolve) => {
					this.$http
						.post("/api/sys_dept_role_related_list", {
							dept_id: id,
						})
						.then((res) => {
							this.formData.depts[index].deptRoleList = res.data.data;
							this.formData = {
								..._this.formData,
							};
							resolve();
						});
				});
			},
			// 处理没有children的分组
			deleteEmptyGroup(treeData) {
				return new Promise((resolve) => {
					function traversal(data) {
						data.map((item) => {
							for (let info in item) {
								if (item["children"]) {
									if (item["children"].length > 0) {
										traversal(item["children"]);
									}
									if (item["children"].length == 0) {
										delete item["children"];
									}
								}
							}
						});
					}

					traversal(treeData);
					resolve(treeData);
				});
			},

			//table
			getList() {
				let params = {
					page: this.page.pageIndex,
					pagesize: this.page.pageSize,
					ktype: this.searchForm.ktype,
					keyword: this.searchForm.keyword,
					all: this.userinfo.username.indexOf("admin") >= 0 ? 1 : 0,
				};
				// sys_dept_user_related_list
				this.$http.post("/api/zichan_teachers_list", params).then((res) => {
					let rData = res.data.data;
					if (rData && rData.length > 0) {
						rData.map((r) => {
							if (!r.preparation_status) {
								r.preparation_status = "";
							}
							if (r.depts && r.depts.length > 0) {
								// console.log(r)
								r.depts.map((a) => {
									a.deptRoles &&
										a.deptRoles.length > 0 &&
										a.deptRoles.map((b) => {
											// console.log(1,a.dept_name)
											// a.dept_name = `${a.dept_name}-${b.role_name}`
											// console.log(2,a.dept_name)
										});
								});
							}
						});
						this.tableData = res.data.data;
						this.baseList = res.data.data;
						this.page.total = res.data.page.count;
					}
				});
			},
			handleNodeClick(data) {
				console.log(data);
				this.deptId = data.id;
				this.deptInfo = data;
				this.tabMapOptions[0].label = `${data.dept_name}`;
				this.page.showPage = false;
				this.searchForm = {
					ktype: "username",
				};
				this.tableData = data.teachers;
				this.baseList = data.teachers;
			},
			formAdd() {
				let _this = this;
				this.modalTitle = "添加教师";
				this.editOptions = {
					sort: 500,
					locked: 0,
				};
				setTimeout(function() {
					_this.showForm();
				}, 50);
			},
			handleModify(row) {
				let _this = this;
				this.modalTitle = "修改教师信息";
				this.teacherId = row.teacher_id;
				let ids = [];
				if (row.dept && row.dept.length > 0) {
					ids = row.dept.map((item, index) => {
						return item.dept_id;
					});
					row.dept_id = ids;
				}
				this.editOptions = row;

				row.depts.map((item) => {
					if (item.dept_name) {
						console.log(item.dept_name);
						this.editOptions.dept_id = item.dept_id;
					}
				});
				setTimeout(function() {
					_this.showForm();
				}, 50);
			},
			handleDel(row) {
				this.$http
					.post("/api/t_sch_teachers_delete", {
						id: row.id,
					})
					.then((res) => {
						this.$message.success("删除成功");
						this.getList();
					});
			},
			handlePageChange(e) {
				this.page.pageIndex = e;
				this.getList();
			},

			//多元素筛选
			multiFilter(array, filters) {
				console.log(array, filters);
				const filterKeys = Object.keys(filters);
				// filters all elements passing the criteria
				return array.filter((item) => {
					// dynamically validate all filter criteria
					return filterKeys.every((key) => {
						//ignore when the filter is empty Anne
						if (!filters[key] || !filters[key].length) return true;
						return !!~filters[key].indexOf(item[key]) || !!~item[key].indexOf(filters[key]);
					});
				});
			},

			onSearch() {
				let _this = this;
				this.page.pageIndex = 1;
				if (_this.deptId) {
					_this.page.showPage = false;
					let obj = {};
					obj[_this.searchForm.ktype] = _this.searchForm.keyword;
					let arr = _this.multiFilter(_this.baseList, obj);
					this.tableData = arr;
				} else {
					this.page.showPage = true;
					this.tableData = [];
					this.getList();
				}
				this.$forceUpdate();
			},
			onReset() {
				this.searchForm = {
					ktype: "username",
				};
				this.tableData = [];
				this.page.pageIndex = 1;
				this.page.showPage = true;
				this.tabMapOptions[0].label = `全部教师`;
				this.deptId = null;
				this.deptInfo = {};
				this.getList();
			},
			handleCascaderChange(e, index) {
				console.log(1, e);
				this.getDeptRoleList(e, index);
			},
			handleSelectionChange(val) {
				let _this = this;
				_this.multipleSelection = val;
			},
			mutiDel() {
				let ids = [];
				if (this.multipleSelection && this.multipleSelection.length > 0) {
					ids = this.multipleSelection
						.map((item, index) => {
							return item.id;
						})
						.join(",");
					this.handleDel({
						id: ids,
					});
				} else {
					this.$message.warning("请至少选择一条数据");
				}
			},
			resetPwd(item) {
				this.$confirm("重置密码为手机号后六位，确定重置?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						this.$http
							.post("/api/reset_teacher_pwd", {
								teacher_id: item.id,
							})
							.then((res) => {
								this.$message({
									type: "success",
									message: "重置成功",
								});
							});
					})
					.catch(() => {});
			},
			releaseDept(item) {
				this.$confirm("解除该用户的所有部门关联，是否确定解除?", "提示", {
						confirmButtonText: "确定解除",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						this.$http
							.post("/api/sys_dept_user_related_delete", {
								teacher_id: item.id,
							})
							.then((res) => {
								this.getList();
								this.$message({
									type: "success",
									message: "已解除部门关联",
								});
							});
					})
					.catch(() => {});
			},
			showOneDept(t) {
				
				let _this = this;
				this.tchId = t.id;
				this.isShowDept = true;
				this.formData = {
					depts: [{
						dept_id: "",
						role_id: 0,
						deptRoleList: [],
					}, ],
				};
				let deptList = [...t.depts],
					tempArr = [];
				if (deptList && deptList.length > 0) {
					deptList.map((a, i) => {
						a.id = a.dept_id;
						a.role_id = 0;
						if (a.deptRoles && a.deptRoles.length > 0) {
							a.role_id = [];
							a.deptRoles.map((b, indexb) => {
								a.role_id.push(Number(b.role_id));
							});
						} else {
							a.role_id = 0;
						}
						
						tempArr.push(a);

						// if (!tempArr || tempArr.length == 0) {
						// 	tempArr.push(a);
						// } else {
						// 	let ids = "";
						// 	ids = tempArr
						// 		.map((r) => {
						// 			return r.dept_id;
						// 		})
						// 		.join(",");
						// 	console.log(652, ids);
						// 	tempArr.map((t) => {
						// 		if (ids.indexOf(a.dept_id) > 0) {
						// 			t.deptRoles = t.deptRoles.concat(a.deptRoles);
						// 		} else {
						// 			tempArr.push();
						// 		}
						// 	});
						// }
						
						this.formData.depts = [...tempArr];
						this.formData = {
							..._this.formData,
						};
					});
					tempArr &&
						tempArr.length > 0 &&
						tempArr.map((aa, ia) => {
							_this.getDeptRoleList(aa.dept_id, ia);
						});
				}
			},
			setDept() {
				this.tchId = null;
				if (this.multipleSelection.length <= 0) {
					this.$message.error("请选择教师");
					return;
				}
				this.formData = {
					depts: [{
						dept_id: "",
						role_id: 0,
						deptRoleList: [],
					}, ],
				};
				this.isShowDept = true;
			},

			addDept() {
				let _this = this;
				console.log(this.formData.depts);
				if (this.formData.depts && this.formData.depts.length > 0) {
					this.formData.depts.push({
						dept_id: "",
						role_id: 0,
					});
				} else {
					this.formData.depts = [];
					this.formData.depts.push({
						dept_id: "",
						role_id: 0,
					});
				}
				this.formData = {
					..._this.formData,
				};
			},
			delDept(index) {
				let _this = this;
				this.formData.depts.splice(index, 1);
				this.formData = {
					..._this.formData,
				};
			},

			saveDept() {
				let json = [];
				if (this.multipleSelection && this.multipleSelection.length > 0) {
					this.multipleSelection.map((m) => {
						if (this.formData.depts && this.formData.depts.length >= 0) {
							if (false) {
								this.$message.error("请选择部门");
								return;
							} else {
								this.formData.depts.map((a, i) => {
									a.teacher_id = Number(m.id);
									a.dept_id = Number(a.dept_id);
									a.sort = 1;
									// console.log(a)

									if (a.dept_id) {
										if (a.role_id && a.role_id.length > 0) {
											a.role_id.map((b) => {
												let obj = {};
												obj.teacher_id = Number(a.teacher_id);
												obj.dept_id = Number(a.dept_id);
												obj.role_id = Number(b);
												obj.sort = 1;
												json.push(obj);
											});
										} else {
											let obj = {
												teacher_id: Number(a.teacher_id),
												dept_id: Number(a.dept_id),
												role_id: 0,
												sort: 1,
											};
											json.push(obj);
										}
									}
								});
							}
						} else {
							this.$message.error("请选择部门");
							return;
						}
					});
				} else {
					if (this.formData.depts && this.formData.depts.length >= 0) {
						if (false) {
							this.$message.error("请选择部门");
							return;
						} else {
							this.formData.depts.map((a, i) => {
								a.teacher_id = Number(this.tchId);
								a.dept_id = Number(a.dept_id);
								a.sort = 1;
								// console.log(a)

								if (a.dept_id) {
									if (a.role_id && a.role_id.length > 0) {
										a.role_id.map((b) => {
											let obj = {};
											obj.teacher_id = Number(a.teacher_id);
											obj.dept_id = Number(a.dept_id);
											obj.role_id = Number(b);
											obj.sort = 1;
											json.push(obj);
										});
									} else {
										let obj = {
											teacher_id: Number(a.teacher_id),
											dept_id: Number(a.dept_id),
											role_id: 0,
											sort: 1,
										};
										json.push(a);
									}
								}
							});
						}
					} else {
						this.$message.error("请选择部门");
						return;
					}
				}

				console.log(json);

				this.$http
					.post("/api/sys_dept_user_related_edit", {
						json: json,
					})
					.then((ok) => {
						if (ok.data.code != 200) {
							this.$message.success("error!");
							return;
						} else {
							this.$message.success("设置成功");
							this.isShowDept = false;
							this.getList();
						}
					});
				return;

				console.log(this.deptInfo);
			},

			setAuth() {
				this.tchId = null;
				if (this.multipleSelection <= 0) {
					this.$message.error("请选择教师");
					return;
				}
				this.isShowRole = true;
			},

			saveRole() {
				let roleid = this.$refs["authTree"]
					.getCheckedNodes(false, true)
					.map((e) => {
						return e.id;
					})
					.join(",");
				// if (!roleid) {
				// 	this.$message.error("请选择角色");
				// 	return;
				// }
				if (this.tchId) {
					this.$http
						.post("/api/zichan_set_user_role", {
							id: String(this.tchId),
							roleids: String(roleid),
						})
						.then((ok) => {
							this.$message.success("设置成功");
							this.isShowRole = false;
							this.getList();
						});
				} else {
					let teacher_id = this.multipleSelection
						.map((e) => {
							return e.id;
						})
						.join(",");
					this.$http
						.post("/api/set_user_role", {
							teacher_id: String(teacher_id),
							roleid: String(roleid),
						})
						.then((ok) => {
							this.$message.success("设置成功");
							this.isShowRole = false;
							this.getList();
						});
				}
			},
			showOneTeacher(t) {
				console.log(t);
				this.tchId = t.id;
				this.isShowRole = true;
				if (t.roles && t.roles.length > 0) {
					this.$nextTick(() => {
						this.$refs["authTree"].setCheckedKeys(
							t.roles.map((e) => {
								return e.role_id;
							})
						);
					});
				}
			},

			showForm() {
				this.$refs.visiable_form.show();
			},
			saveForm(e) {
				console.log(e);
				let _this = this;
				this.$http.post("/api/t_sch_teachers_edit", e).then((res) => {
					this.$message.success("保存成功");
					this.$refs.visiable_form.handleCancel();
					this.getList();
				});
			},

			handleSizeChange(val) {
				this.page.pageIndex = 1;
				this.page.pageSize = val;
				this.getList();
			},
			paginate(val) {
				this.page.pageIndex = val;
				this.getList();
			},
		},
	};
</script>
<style scoped lang="less" type="text/less">
	.teacherBox {
  box-sizing: border-box;
  box-shadow: 0 0 5px #409eff81;
  padding-left: 10px;
  padding-bottom: 10px;
  position: relative;
  overflow: auto;
  width: 100%;
  height: calc(100% - 00px);

  .elRowCus,
  .elRowCus .el-col {
    height: 100%;
  }

  .el-tabs {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .el-tabs__content {
    flex: 1;

    .el-tab-pane {
      height: 100%;
    }
  }
}
</style>