//判空
const isEmpty = function (v) {
	switch (typeof v) {
		case 'undefined':
			return true;
		case 'string':
			if (v.replace(/(^[ \t\n\r]*)|([ \t\n\r]*$)/g, '').length == 0) return true;
			break;
		case 'boolean':
			if (!v) return true;
			break;
		case 'number':
			if (0 === v || v) return true;
			break;
		case 'object':
			if (null === v || v.length === 0) return true;
			for (var i in v) {
				return false;
			}
			return true;
	}
	return false;
}
//助学金--评定级别
const grantlevel = [
	{name:'自定义级别1',value:1},
	{name:'自定义级别2',value:2},
	{name:'自定义级别3',value:3},
]
//处分
/**处分类型*/
const breachtype = [
	{name:'自定义类型1',value:1},
	{name:'自定义类型2',value:2},
	{name:'自定义类型3',value:3},
]
//评优
/**课程分类/课程性质/考试性质*/
const coursecategory = [
	{name: "公共课", value: 0},
	{name: "专业课", value: 1},
	{name: "实训课", value: 2},
]
const coursetype = [
	{name:"必修", value: 0},
	{name:"选修", value: 1},
	{name:"任选", value: 2},
]
const examtype = [
	{name:"考试", value: 0},
	{name:"考察", value: 1},
]

const awardlevel = [
	{name:"校内", value: 1},
	{name:"区级", value: 2},
	{name:"市级", value: 3},
	{name:"省级", value: 4},
	{name:"国家级", value: 5},
]
//获奖规则级别
const awardRulelevel = [
	{name:"一等奖", value: 1},
	{name:"二等奖", value: 2},
	{name:"三等奖", value: 3},
]

//获奖类型
const rewardtype = [
	{name:"活动获奖记录", value: 0},
	{name:"社会实践", value: 1},
	{name:"好人好事", value: 2},
]
//德育-评价类型
const committype = [
	{name:"校内卫生", value: 1},
	{name:"违纪", value: 2},
	{name:"自定义评价类型3", value: 3},
]

// 学生-培养方式
const stuEduType = [
	{name:"定向培养", value: 1},
	{name:"非定向培养", value: 2},
]
// 学生-民族
const stuNation = [
	{name:"汉族", value: 1},
	{name:"苗族", value: 2},
	{name:"维吾尔族", value: 3},
	{name:"壮族", value: 4},
]
// 学生-政治面貌
const stuPolitical = [
	{name:"团员", value: 1},
	{name:"预备党员", value: 2},
	{name:"党员", value: 3},
]
// 教师-编制类型
const teaUnitType = [
	{name:"编制", value: 1},
	{name:"非编制", value:0},
]
// 教师-教师状态
const teaStatus = [
	{name:"正常", value: 1},
	{name:"休假", value: 2},
]
// 学年
const year = [
	{name:"遇到此处请提交bug单", value: '2020'},
]
// 职称
const job1 = [
	{name:"正高级", value: 1},
	{name:"副高级", value: 2},
	{name:"中级", value: 3},
	{name:"初级", value: 4},
	{name:"员级", value: 5},
]
// 职级
const job2 = [
	{name:"高校教师", value: 1},
	{name:"教授", value: 2},
	{name:"副教授", value: 3},
]
// 学位
const edu1 = [
	{name:"博士学位", value: 1},
	{name:"硕士学位", value: 2},
	{name:"学士学位", value: 3},
]
// 学历
const edu2 = [
	{name:"博士学历", value: 1},
	{name:"硕士学历", value: 2},
	{name:"本科学历", value: 3},
	{name:"大专学历", value: 4},
	{name:"中专/中技学历", value: 5},
]
export default {
	job1,job2,edu1,edu2,
	isEmpty,
	grantlevel,
	breachtype,
	coursecategory,coursetype,examtype,
	awardlevel,
	awardRulelevel,
	rewardtype,
	committype,
	stuEduType,	stuNation, stuPolitical,
	teaUnitType, teaStatus,
	year,
}
